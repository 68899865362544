import React from 'react';
import { Link } from "gatsby"
import FacebookBadge from '../../components/FacebookBadge';
import Layout from '../../components/layout';
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import Grid from '@material-ui/core/Grid';
import { faClock, faUser} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './articles.css';

//Import At least four Images for the Article
import articleImage_1 from '../../images/services/Savente-Consulting-Limited-Services-01.jpg';
import articleImage_2 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-03.jpg';
import articleImage_3 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-01.jpg';
import articleImage_4 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-04.jpg';

const articleDetails = {
         title: "Product Registration in CEMAC, ECOWAS and UEMOA Regions, Similarities and Differences",
         date: "13th March, 2021",
         datePublished: "2021-03-13T08:00:00+08:00", // Y-M-d
         dateModified: "2021-03-13T08:00:00+08:00", //
         author: "Savante Consulting Limited",
         supurb: "Product Registration for clinical research organizations in Nigeria and CEMAC, ECOWAS and UEMOA Regions, Similarities and Differences. Pharmaceuticals and other medical devices are important for national developments."
    }
;

const images = [
    {
      image: articleImage_1,
      alt: 'Face Painting',
      key:1,
    },
    {
        image: articleImage_2,
        alt: 'Face Painting',
        key:2,
    },
    {
        image: articleImage_3,
        alt: 'Face Painting',
        key:3,
    },
    {
        image: articleImage_4,
        alt: 'Face Painting',
        key:4,
    },
  ];

const listItems = images.map((image) => <div key={image.key}  className="col s12 l3 hide-on-small-only">
        <img src={image.image} className="responsive-img" alt={image.alt} />
        </div>);

export default function ProductRegistration(){

    return(
        <Layout>
            <article>
            <SEO 
                title={articleDetails.title} 
                description={articleDetails.supurb}
                image={articleImage_1}

            />
            <Helmet>
            <script type="application/ld+json">
                {`                    
                      {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "`+articleDetails.title+`",
                        "image": [
                          "`+articleImage_1+`",
                          "`+articleImage_2+`",
                          "`+articleImage_3+`",
                          "`+articleImage_4+`",
                         ],
                        "datePublished": "`+articleDetails.datePublished+`",
                        "dateModified": "`+articleDetails.dateModified+`"
                      }
                  
                  
                `}
            </script>
            </Helmet>
            <br />
            <br />
            <div className="container article">


                <h1 className="title secondary-color-dark-text mb-5">{articleDetails.title}</h1>     
                <Grid container>
                            <Grid  item xs={12}>
                                <time className="pr-10">
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faClock} />
                                    <span>{articleDetails.date}</span>
                                </time>
                                <time>
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faUser} />
                                    <span>{articleDetails.author}</span>
                                </time>
                            </Grid>
                </Grid>
                <div className="row">
                    <div className="col s12 l8 article-body">

                        <Link to="/contact-us" className="btn btn-small waves-effect waves-light  getqouteButton hide-on-large-only  mb-10 mt-10" style={{width: '100%'}}>Contact Us</Link>   

                        <div class="justify">
                            <p>Products such as foods, biologicals, pharmaceuticals and other medical devices are important for national developments. Every country needs a reliable supply of all these products to promote safety, improve public health and patient care [1]. Sound and effective regulatory systems are needed to ensure their quality, safety and efficacy [2]</p>
                            <p>In order to ensure such products intended for human use fulfil the safety needs of patients, regulatory approval in the form of marketing authorization must be granted before their entry into any local or international market. Marketing authorization can only be granted if the documents submitted for a product approval fulfil all the requirements set in the guidelines by the regulatory authority.</p>
                            <p>Over the years, the regulatory landscape in Africa is fast developing and has changed remarkably. Almost every country in Africa currently as a regulatory authority [3]. Though they are still at different levels of growth, maturity and expertise.</p>
                            <p>In other to witness synergies in regulatory policy and market forces, most countries in Africa have started participating in various regional and subregional activities. Some have developed guidelines and even joined subregional harmonisation plans. This has helped in improving approval and review processes to match the increasing complexity of various product applications.</p>
                            <p>Some of the regional groups in Africa include The Economic and Monetary Community of Central Africa (CEMAC), The West African Monetary and Economic Union (UEMOA), The Economic Community of West African States ECOWAS (CEDEAO), African Regulatory Organization (AFRO). etc.</p>
                            <p>The countries associated with the CEMAC region are <em><u>Cameroon</u></em><em>, <u>Central </u></em><em><u>African</u></em><em><u> Republic</u></em><em>, <u>Congo</u>, <u>Chad</u> and <u>Equatorial Guinea</u></em>. These countries follow the European Union regulation and solely rely on Cameroon because it has the biggest market in the region.</p>
                            <p>The ECOWAS (CEDEAO in French) region includes the Anglophone and Francophone countries. Anglophone countries include <em>Cape Verde, <u>the Gambia</u>, <u>Ghana</u>, <u>Liberia</u>, <u>Nigeria</u> and <u>the Republic of Guinea</u></em>.</p>
                            <p>The Francophone countries are also known as The West African French-speaking countries form an organization called The West African Monetary and Economic Union (WAEMU) (also known under the French acronym UEMOA). It includes countries such as <em><u>Benin</u>, <u>Burkina Faso</u>, <u>Ivory Coast</u>, <u>Mali</u>, <u>Niger</u>, <u>Senegal </u>and <u>Togo</u></em> [4].</p>
                            <p>Certain regulatory functions are common to both CEMAC, ECOWAS or UEMOA. In all the 3 regions, application for product registration usually requires the following;</p>
                            <ol>
                                <li>An applicant</li>
                                <li>Local responsible person: Applicant not residing in any of the member state/regions shall appoint an Authorised person which authorizes him to speak for his Principal, on all matters relating to the registration of the product.</li>
                            </ol>
                            <ul>
                                <li>Documentation: Document such as a power of attorney, notarized declaration, Certificate of Manufacture and Free Sale, Evidence of business incorporation in the country, labelling information, Safety data sheets etc may be required.</li>
                            </ul>
                            <p>However, the documents required to be submitted for registration will depend on whether the product falls into the food, pharmaceuticals or other categories.</p>
                            <ol>
                                <li>Certificate of analysis of the product from an accredited laboratory.</li>
                                <li>Artwork or label of the product.</li>
                                <li>International food standard CODEX Alimentarius.</li>
                            </ol>
                            <p>Some of the differences in key regulatory functions are in terms of;</p>
                            <ol>
                                <li>Application language: Product applications in each region are written in French (CEMAC), English (ECOWAS) French or English (UEMOA).</li>
                                <li>Timeline for evaluation and approval varies according to the different class of products.</li>
                            </ol>
                            <ul>
                             <li>Fees</li>
                            </ul>
                            <ol>
                            <li>Custom/Import etc.</li>
                            </ol>
                            <p>Regulatory authorities in Africa are still a work in progress. The major goal of every regulatory body/region/sub-regions in Africa is to provide the African populace with a quality and safe product at a lower price. However, the differences that arise in each region and their practices have potentially delayed the importation and availability of imported products into some of Africa&rsquo;s market.</p>
                            <p><strong>References</strong></p>
                            <ol>
                            <li>Marucheck, A., Greis, N., Mena, C., and Cai, L. (2011). Product safety and security in the global supply chain: Issues, challenges and research opportunities. Journal of Operations Management, 29(7-8), 707&ndash;720.</li>
                            <li>FDA (Food and Drug Administration). About FDA: What we do. 2010. <a rel="noreferrer" target="_blank" href="http://www.fda.gov/aboutfda/whatwedo/default">http://www.fda.gov/aboutfda/whatwedo/default</a>. htm (accessed March 1, 2012).</li>
                            <li><a rel="noreferrer" target="_blank" href="https://www.rrfa.co.za/">https://www.rrfa.co.za/</a></li>
                            <li><a rel="noreferrer" target="_blank" href="http://www.uemoa.int/en/about-uemoa">http://www.uemoa.int/en/about-uemoa</a></li>
                            </ol>
                        </div>
                        
                    </div>
                    <div className="col s12 l4 p-0 center">
                        <Link to="/contact-us" className="btn btn-small waves-effect waves-light hide-on-small-only contact-us-btn" style={{width: '100%'}}>Contact Us</Link>
                        <br />
                        <br />
                        <FacebookBadge
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsavanteconsultingofficial%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
                        />
                        <br />
                        <iframe 
                            title="Google Badge"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5355607532397!2d3.387330214770342!3d6.45361289533113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b568f3f5fc1%3A0xb2c59461ac52d3b7!2sSavante%20Consulting%20Limited!5e0!3m2!1sen!2sru!4v1615677592874!5m2!1sen!2sru"
                            allowFullScreen=""
                            width="340"
                            height="300" 
                            frameBorder="0">

                        </iframe>
                    </div>
                </div>
            </div>
            </article>
        </Layout>
    );
}